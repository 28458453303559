<template>
	<Modal
		:title="'上传头像'"
		:value="visible"
		width="500"
		:mask-closable="false"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>

		<div class="content-wrap">
			<div class="picture-wrap">
				<div class="picture-design">
					<Upload
						action="/"
						accept="image/png, image/jpeg, image/jpg"
						:before-upload="beforeUpload"
					>
						<Button :loading="loading">选择图片</Button>
					</Upload>
					<div class="upload-info">
						<p>只支持上传png、jpg格式。</p>
					</div>
					<div
						class="picture-content"
						:style="{ height: dataGridHeight + 'px' }"
					>
						<vueCropper
							ref="cropper"
							:autoCropWidth="200"
							:autoCropHeight="200"
							:fixedBox="true"
							:img="img"
							:autoCrop="true"
							:high="false"
							mode="contain"
						></vueCropper>
					</div>
					<div class="action-button">
						<Button :disabled="!img" @click="changeScale(2)">
							<Icon size="24" type="ios-add" />
						</Button>
						<Button :disabled="!img" @click="changeScale(-2)">
							<Icon size="24" type="ios-remove" />
						</Button>
						<Button :disabled="!img" @click="rorateLeft">
							<Icon size="24" type="md-undo" />90°
						</Button>
						<Button :disabled="!img" @click="rorateRight">
							<Icon size="24" type="md-redo" />90°
						</Button>
					</div>
				</div>
			</div>
		</div>

		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">取消 </Button>
			<Button
				:disabled="!img"
				type="primary"
				:loading="loading"
				@click="handleSubmit"
				>确定</Button
			>
		</div>
	</Modal>
</template>

<script>
import { VueCropper } from "vue-cropper"
import { mapState } from "vuex"
import api from "@/api/personalInfo/profilePicture"
import { formDataToMD5 } from "@/utils/util"

const { uploadUserProfilePicture } = api

export default {
	name: "UploadProfilePicture",
	props: {
		visible: {
			default: false
		}
	},
	data() {
		return {
			img: "",
			signUrl: "",
			loading: false,
			file: null,
			fileMd5: ""
		}
	},
	components: {
		VueCropper
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 185
		})
	},
	created() {
		// this.isHasSign();
	},
	methods: {
		onCancel() {
			this.$emit("onCancel")
		},
		onOk() {
			this.$emit("onOk")
		},
		// 向右旋转
		rorateRight() {
			this.$refs.cropper.rotateRight()
		},
		// 向左旋转
		rorateLeft() {
			this.$refs.cropper.rotateLeft()
		},
		// 放大，缩小
		changeScale(num) {
			this.$refs.cropper.changeScale(num)
		},
		// 提交
		handleSubmit() {
			this.$refs.cropper.getCropData(data => {
				this.$asyncDo(async () => {
					this.loading = true
					const res = await uploadUserProfilePicture(
						this.$store.state.user.info.id,
						{
							file: data,
							md5: this.fileMd5,
							fileName: this.file.name
						}
					)
					if (res) {
						this.$Message.success("操作成功!")
						this.signUrl = ""
						// 清空时，需要设置一个找不到地址的图片
						this.img = "/noUrl.png"
						this.file = null
						this.$nextTick(() => {
							this.$refs.cropper.refresh()
						})
						this.onOk()
					}
					this.loading = false
				})
			})
			return true
		},
		// 图片处理成base64
		beforeUpload(file) {
			if (!/\.(jpg|jpeg|png|JPG|PNG)$/.test(file.name)) {
				this.$Message.error("图片类型必须是.jpg,png中的一种")
				return false
			}
			let data
			if (typeof file === "object") {
				// 把Array Buffer转化为blob 如果是base64不需要
				data = window.URL.createObjectURL(new Blob([file]))
				this.file = file
			} else {
				data = file
			}
			this.img = data
			formDataToMD5(file, md5Str => {
				this.fileMd5 = md5Str
			})
			return false
		}
	}
}
</script>

<style lang="less">
.picture-wrap {
	display: flex;
	.picture-design {
		flex: 1;
		padding-left: 16px;
		.picture-content {
			height: 500px;
		}
		.upload-info {
			line-height: 30px;
			color: #ff4e00;
		}
		.action-button {
			padding: 10px 0;
			text-align: center;
			.ivu-btn {
				margin: 0 10px;
			}
		}
		.picture-footer {
			text-align: right;
			.ivu-btn {
				margin-right: 10px;
			}
		}
	}
}
</style>
