import request from "@/utils/request"
import commonApi from "@/api/api"

const moduleName = "/auth/self-info/cv"
export default {
	...commonApi(moduleName),
	apiUpdate: data =>
		request(`${moduleName}`, {
			method: "put",
			body: data
		}),
	apiGetFiles: data =>
		request(`${moduleName}/${data.id}/files`, {
			method: "get"
		}),
	// 上传文件
	uploadFile: (data, params) =>
		request("/document/attachment/user-audit-file", {
			method: "post",
			body: data,
			params
		}),
	getTrainHistory: () => request("/training-user/trainings"),
	// 新增个人培训记录
	apiAddTrain: data =>
		request("/training-user/training", {
			method: "post",
			body: data
		}),
	// 修改个人培训记录
	apiEditTrain: data =>
		request(`/training-user/trainings/${data.trainingId}`, {
			method: "put",
			body: data
		}),
	// 删除个人培训记录
	apiDelTrain: trainingId =>
		request(`/training-user/trainings/${trainingId}`, {
			method: "delete"
		}),
	// 获取用户简历信息
	apiGetResume: userId =>
		request(`/auth/user/${userId}/cv`, {
			method: "get"
		}),
	// 获取用户培训信息
	apiGetTrain: userId =>
		request(`/auth/user/${userId}/trainings`, {
			method: "get"
		})
}
