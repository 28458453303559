import request from "@/utils/request"

const getRequest = (url, data = null, method = "get") =>
	request(url, Object.assign({ method }, data || {}))
const post = (url, data = null) =>
	getRequest(url, data && data.body ? data : { params: data }, "post").then(
		resp => resp.data
	)

export default {
	// 获取系统指定最近年份的财务报表
	uploadUserProfilePicture: (userId, data) =>
		post(`/auth/user/${userId}/photo`, { body: data })
}
